<!-- editRole -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'companyRoles',
          name: 'Roles',
        },
        {
          name: roleData.name,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="Roles">
      <template v-slot:left>
        <ul class="roleinfo-card">
          <li>
            <div class="roleinfo-card-lable">
              {{ roleData.type === "0" ? "System role" : "Custom role" }}
            </div>
            <div class="roleinfo-card-name">{{ roleData.name }}</div>
            <div class="roleinfo-card-describ">{{ roleData.describ }}</div>
          </li>
          <li>
            <div
              class="operate roleinfo-card-duplicate"
              @click="openFormDialog('duplicate')"
            >
              Duplicate role
            </div>
            <div
              v-if="roleData.type === '1'"
              class="operate roleinfo-card-edit"
              @click="openFormDialog('edit')"
            >
              Edit role info
            </div>
            <div
              v-if="roleData.type === '1'"
              class="operate roleinfo-card-dele"
              :class="{ disabled: roleData.using }"
              @click="handleDelete"
            >
              Delete role
            </div>
          </li>
        </ul>
      </template>
      <div class="roles-content mar-b-20">
        <Section title="Privileges" class="section-nopad">
          <div slot="editright" v-if="!isEdit && roleData.type === '1'">
            <el-tooltip
              content="Edit"
              popper-class="edoovo-table-cell-tooltip"
              :visible-arrow="false"
            >
              <img
                @click="handleEditRole"
                class="icon-edit"
                src="@/assets/images/icons/icon-edit.svg"
                alt=""
              />
            </el-tooltip>
          </div>
          <ul class="privileges-default">
            <li v-for="(item, index) in formDataPrivilege" :key="index">
              <div class="privileges-default-header">
                <img
                  class="privileges-default-header-icon"
                  :src="item.apps"
                  alt=""
                />
                <i class="iconfont iconarrow_drop_down-24px" />
                <md-checkbox
                  :disabled="!isEdit"
                  class="md-primary"
                  v-model="item.ismodulePermission"
                  >{{ item.moduleName }}</md-checkbox
                >
              </div>
              <ul class="privileges-default-list">
                <li
                  v-for="(privilege, subIndex) in item.privilegeList"
                  :key="subIndex"
                >
                  <div class="privileges-default-space"></div>
                  <div class="privileges-default-role">
                    {{ privilege.name }}
                  </div>
                  <div
                    class="privileges-default-describcontent"
                    v-if="item.ismodulePermission"
                  >
                    <div class="privileges-default-privilege">
                      <baseGGSelect
                        :disabled="!isEdit"
                        v-model="privilege.optionsVal"
                        :inline="true"
                      >
                        <md-option
                          v-for="option in privilege.options"
                          :key="option"
                          :value="option"
                        >
                          {{ option }}
                        </md-option>
                      </baseGGSelect>
                    </div>

                    <el-tooltip
                      v-if="privilege.optionsVal === 'View'"
                      :content="`${privilege.moduleDesc['View']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                        {{ `${privilege.moduleDesc["View"]}` }}
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="privilege.optionsVal === 'Create'"
                      :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                       {{
                         `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}`:`${privilege.moduleDesc["View"]}`}`
                       }}
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="privilege.optionsVal === 'Edit'"
                      :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}, ${privilege.moduleDesc['Edit']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                      <!--  {{
                          `${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}`
                        }} -->
						{{
						  `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}`
						  :`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Edit"]}`}`
						}}
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="privilege.optionsVal === 'Full'"
                      :content="`${privilege.moduleDesc['View']}, ${privilege.moduleDesc['Create']}, ${privilege.moduleDesc['Edit']}, ${privilege.moduleDesc['Full']}`"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                    >
                      <div class="privileges-default-desc">
                     <!--   {{
                          `${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}, ${privilege.moduleDesc["Full"]}`
                        }} -->
						{{
						  `${privilege.moduleDesc["Create"]?`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Create"]}, ${privilege.moduleDesc["Edit"]}, ${privilege.moduleDesc["Full"]}`
						  :`${privilege.moduleDesc["View"]}, ${privilege.moduleDesc["Edit"]}`}`
						}}
                      </div>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </Section>
         <div class="text-right" v-if="isEdit">
        <BaseButton @click="handleCancel">Cancel</BaseButton>
        <BaseButton type="primary" :disabled="isDisabled" @click="handleSubmit"
          >Save</BaseButton
        >
      </div>
      </div>
      <!-- <div class="text-right" v-if="isEdit">
        <BaseButton @click="handleCancel">Cancel</BaseButton>
        <BaseButton type="primary" :disabled="isDisabled" @click="handleSubmit"
          >Save</BaseButton
        >
      </div> -->
    </GgPage>
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      :renderPass="formDialogAdd.renderPass"
      @submit="handleAddForm"
      textReminder="* indicates a required field"
      cancelBtnTxt="Cancel"
      :submitBtnTxt="formDialogAdd.submitBtnTxt"
    >
      <template slot="renderHtml" slot-scope="scope">
        <gg-input
          v-if="scope.data.key === 'name'"
          :key="scope.data.key"
          :ref="scope.data.key"
          v-model="scope.data.value"
          type="input"
          :placeholder="scope.data.placeholder"
          :error="errorName"
        />
      </template>
    </BaseFormDialog1>
    <gg-Dialog
      title="Save changes"
      class="noborder-dialog"
      :visible.sync="formDialogView.visible"
      :show-close="true"
      :before-close="
        () => {
          formDialogView.visible = !formDialogView.visible;
        }
      "
    >
      <div class="dialog-wrapper">
        You have some unsaved changes. Please save them before you continue.
      </div>
    </gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { lightFormat } from "date-fns";
import { mapState } from "vuex";
export default {
  components: {},
  computed: {
    errorName() {
      let nameVal = this.formDialogAdd.dataList.find(
        (item) => item.key === "name"
      ).value;

      if (nameVal === "") {
        return {
          show: true,
          message: "Role name required",
        };
      }

      let isPass = nameVal.length < 40;
      return {
        message: "Character limit of 80",
        show: !isPass,
      };
    },
  },
  watch: {
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
			console.log(res)
          res.renderPass = !this.$refs.name.error.show;
          console.log(res.renderPass);
        });
      },
    },
    formDataPrivilege: {
      deep: true,
      handler: function (res) {
        this.$nextTick(() => {
          let permission = res.some((item) => item.ismodulePermission);
          this.isDisabled = !permission;
        });
      },
    },
  },
  data() {
    return {
      id: "",
      isEdit: false,
      roleData: [],
      isDisabled: true,
      formDialogAdd: {
        visible: false,
        title: "",
        duplicate: "Duplicate role",
        edit: "Edit role info",
        submitBtnTxt: "Save",
        renderPass: false,
        dataList: [
          {
            placeholder: "Name *",
            key: "name",
            value: "",
            renderHtml: true,
            // reg: /\S/,
            // errorText: "Character limit of 20",
          },
          {
            placeholder: "Description",
            key: "describ",
            value: "",
            reg: /^[\s\S]{0,80}$/,
            errorText: "describ limit of 80",
          },
        ],
      },
      formDataPrivilege: [
        {
          moduleName: "Staff",
          apps: require("@/assets/images/menu/staff.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Staff",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all staff records for a location",
                Create: "add a new staff record",
                Edit: "edit a staff record, reset staff password",
                Full:
                  "delete, archive and restore staff record",
              },
            },
          ],
        },
        {
          moduleName: "Students",
          apps: require("@/assets/images/menu/students.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Students",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all students records for a location",
                Create: "enroll a new student record",
                Edit: "edit a student record",
                Full:
                  "delete,defer a student, return a student, withdraw a student, enroll a withdrawn student",
              },
            },
            {
              name: "Students contacts",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all contact info of a student",
                Create: "add all kinds of contact to a student",
                Edit: "edit all info of a contact, reset family password",
                Full:
                  "mark as primary contact, delete a contact, invite an added family member to the system, resend the invitation email to invite the family member, disconnect a family account with the student",
              },
            },
          ],
        },
        {
          moduleName: "People",
          apps: require("@/assets/images/menu/people.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "People",
              optionsVal: "View",
              options: ["View"],
              moduleDesc: {
                View: "View all people in a location",
              },
            },
          ],
        },
        {
          moduleName: "Classes",
          apps: require("@/assets/images/menu/classes.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Class",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all class records for a location",
                Create: "create a new class",
                Edit: "edit a class info, manage people in a class",
                Full: "delete a class",
              },
            },
            {
              name: "Promote classes",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all class records for a location",
                Create: "",
                Edit: "promote students in a class to another one",
                Full: "",
              },
            },
          ],
        },
        {
          moduleName: "Attendance",
          apps: require("@/assets/images/menu/attendance.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Attendance",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View student attendance records of a class",
                Create: "add student's attendance record",
                Edit: "edit student's attendance record",
                Full: "delete student attendance record",
              },
            },
			{
			  name: "Check-in Kiosk",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all check-in record",
			    Create: "check in/out student",
			    Edit: "check in/out student",
			    Full: "check in/out student",
			  },
			}
          ],
        },
        {
          moduleName: "Commbook",
          apps: require("@/assets/images/menu/commbook.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Messages",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all messages",
                Create: "create a new message and send to seleted parents",
                Edit: "reply a message, edit a message created by own",
                Full: "delete a message created by own",
              },
            },
          ],
        },
        {
          moduleName: "Moments",
          apps: require("@/assets/images/menu/moments.svg"),
          ismodulePermission: false,
          privilegeList: [
			  {
			                name: "Manage media",
			                optionsVal: "View",
			                options: ["View", "Create", "Edit", "Full"],
			                moduleDesc: {
			                  View: "View all media in a class",
			                  Create: "upload media",
			                  Edit: "edit an unshared photo,including edit photo info and add/remove favourite",
			                  Full: "delete an unshared media and download photo",
			                },
			              },
			              {
			                name: "Share media",
			                optionsVal: "View",
			                options: ["View", "Create", "Edit", "Full"],
			                moduleDesc: {
			                  View: "View all media in sharing",
			                  Create: "share selected media with parents",
			                  Edit: "undo a photo submission",
			                  Full: "approve or recall a photo sharing",
			                },
			              },
			              {
			                name: "Manage albums",
			                optionsVal: "View",
			                options: ["View", "Create", "Edit", "Full"],
			                moduleDesc: {
			                  View: "View all albums in a class",
			                  Create: "create albums",
			                  Edit: "edit an album, including rename the album and change album cover. manage the photo in the album, including add photo to the album and remove photo from the album",
			                  Full: "delete an album",
			                },
			              },
            // {
            //   name: "Manage media",
            //   optionsVal: "View",
            //   options: ["View", "Create", "Edit", "Full"],
            //   moduleDesc: {
            //     View: "View all media in a class",
            //     Create: "upload media",
            //     Edit: "edit the info of an unshared media",
            //     Full: "delete an unshared media",
            //   },
            // },
            // {
            //   name: "Share media",
            //   optionsVal: "View",
            //   options: ["View", "Create", "Edit", "Full"],
            //   moduleDesc: {
            //     View: "View all media in sharing",
            //     Create: "share selected media with parents ",
            //     Edit: "undo a photo submission",
            //     Full: "approve or recall a photo sharing",
            //   },
            // },
            // {
            //   name: "Manage albums",
            //   optionsVal: "View",
            //   options: ["View", "Create", "Edit", "Full"],
            //   moduleDesc: {
            //     View: "View all albums in a class",
            //     Create: "create albums",
            //     Edit: "edit basic info of an album",
            //     Full: "delete an album",
            //   },
            // },
          ],
        },
        {
          moduleName: "Announcements",
          apps: require("@/assets/images/menu/announcement.svg"),
          ismodulePermission: false,
          privilegeList: [
            {
              name: "Announcements",
              optionsVal: "View",
              options: ["View", "Create", "Edit", "Full"],
              moduleDesc: {
                View: "View all announcements",
                Create: "add a new announcement",
                Edit: "edit an announcement",
                Full:
                  "post, delete, recall an announcement, archive, restore an announcement",
              },
            },
          ],
        },
		{
		  moduleName: "Journal",
		  apps: require("@/assets/images/menu/journal.svg"),
		  ismodulePermission: false,
		  privilegeList: [
		    {
		      name: "Manage journals",
		      optionsVal: "View",
		      options: ["View", "Create", "Edit", "Full"],
		      moduleDesc: {
		        View: "View and preview all journals",
		        Create: "create journals",
		         Edit: "edit unshared journals, print and download journals",
		        Full:
		          "delete an unshared journal",
		      },
		    },
			{
			  name: "Share journals",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all journals in posted",
			    Create: "share selected journal with parents",
			    Edit: "undo a journal submission",
			    Full:
			      "approve or recall a journal submission",
			  },
			},
		  ],
		},
      ],
      formDialogView: {
        visible: false,
      },
      snackbarData: {
        visible: false,
        content: "",
      },
	  id: "",
	  isEdit: false,
	  roleData: [],
	  isDisabled: true,
	  formDialogAdd: {
	    visible: false,
	    title: "",
	    duplicate: "Duplicate role",
	    edit: "Edit role info",
	    submitBtnTxt: "Save",
	    renderPass: false,
	    dataList: [
	      {
	        placeholder: "Name *",
	        key: "name",
	        value: "",
	        renderHtml: true,
	        // reg: /\S/,
	        // errorText: "Character limit of 20",
	      },
	      {
	        placeholder: "Description",
	        key: "describ",
	        value: "",
	        reg: /^[\s\S]{0,80}$/,
	        errorText: "describ limit of 80",
	      },
	    ],
	  },
	  morenlist: [
	    {
	      moduleName: "Staff",
	      apps: require("@/assets/images/menu/staff.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "Staff",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all staff records for a location",
	            Create: "add a new staff record",
	            Edit: "edit a staff record, reset staff password",
	            Full:
	              "delete, archive and restore staff record",
	          },
	        },
	      ],
	    },
	    {
	      moduleName: "Students",
	      apps: require("@/assets/images/menu/students.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "Students",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all students records for a location",
	            Create: "enroll a new student record",
	            Edit: "edit a student record",
	            Full:
	              "delete,defer a student, return a student, withdraw a student, enroll a withdrawn student",
	          },
	        },
	        {
	          name: "Students contacts",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all contact info of a student",
	            Create: "add all kinds of contact to a student",
	            Edit: "edit all info of a contact, reset family password",
	            Full:
	              "delete a contact, invite an added family member to the system, resend the invitation email to invite the family member, disconnect a family account with the student",
	          },
	        },
	      ],
	    },
	    {
	      moduleName: "People",
	      apps: require("@/assets/images/menu/people.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "People",
	          optionsVal: "View",
	          options: ["View"],
	          moduleDesc: {
	            View: "View all people in a location",
	          },
	        },
	      ],
	    },
	    {
	      moduleName: "Classes",
	      apps: require("@/assets/images/menu/classes.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "Class",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all class records for a location",
	            Create: "create a new class",
	            Edit: "edit a class info, manage people in a class",
	            Full: "delete a class",
	          },
	        },
	        {
	          name: "Promote classes",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all class records for a location",
	            Create: "",
	            Edit: "promote students in a class to another one",
	            Full: "",
	          },
	        },
	      ],
	    },
	    {
	      moduleName: "Attendance",
	      apps: require("@/assets/images/menu/attendance.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "Attendance",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View student attendance records of a class",
	            Create: "add student's attendance record",
	            Edit: "edit student's attendance record",
	            Full: "delete student attendance record",
	          },
	        },
			{
			  name: "Check-in Kiosk",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all check-in record",
			    Create: "Check in/out student",
			    Edit: "Check in/out student",
			    Full: "Check in/out student",
			  }, 
			}
	      ],
	    },
	    {
	      moduleName: "Commbook",
	      apps: require("@/assets/images/menu/commbook.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "Messages",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all messages",
	            Create: "create a new message and send to seleted parents",
	            Edit: "reply a message, edit a message created by own",
	            Full: "delete a message created by own",
	          },
	        },
	      ],
	    },
	    {
	      moduleName: "Moments",
	      apps: require("@/assets/images/menu/moments.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "Manage media",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	           View: "View all media in a class",
	           Create: "upload media",
	           Edit: "edit an unshared photo, including edit photo info and add/remove favourite",
	           Full: "delete an unshared media and download photo",
	          },
	        },
	        {
	          name: "Share media",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all media in sharing",
	            Create: "share selected media with parents",
	            Edit: "undo a photo submission",
	            Full: "approve or recall a photo sharing",
	          },
	        },
	        {
	          name: "Manage albums",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	           View: "View all albums in a class",
	           Create: "create albums",
	           Edit: "edit an album, including rename the album and change album cover. manage the photo in the album, including add photo to the album and remove photo from the album",
	           Full: "delete an album",
	          },
	        },
	      ],
	    },
	    {
	      moduleName: "Announcements",
	      apps: require("@/assets/images/menu/announcement.svg"),
	      ismodulePermission: false,
	      privilegeList: [
	        {
	          name: "Announcements",
	          optionsVal: "View",
	          options: ["View", "Create", "Edit", "Full"],
	          moduleDesc: {
	            View: "View all announcements",
	            Create: "add a new announcement",
	            Edit: "edit an announcement",
	            Full:
	              "post, delete, recall an announcement, archive, restore an announcement",
	          },
	        },
	      ],
	    },
		{
		  moduleName: "Journal",
		  apps: require("@/assets/images/menu/journal.svg"),
		  ismodulePermission: false,
		  privilegeList: [
		    {
		      name: "Manage journals",
		      optionsVal: "View",
		      options: ["View", "Create", "Edit", "Full"],
		      moduleDesc: {
		        View: "View and preview all journals",
		        Create: "create journals",
		          Edit: "Edit unshared journals, print and download journals",
		        Full:
		          "delete an unshared journal",
		      },
		    },
			{
			  name: "Share journals",
			  optionsVal: "View",
			  options: ["View", "Create", "Edit", "Full"],
			  moduleDesc: {
			    View: "View all journals in posted",
			    Create: "share selected journal with parents",
			    Edit: "undo a journal submission",
			    Full:
			      "approve or recall a journal submission",
			  },
			},
		  ],
		},
	  ],
	  formDialogView: {
	    visible: false,
	  },
	  snackbarData: {
	    visible: false,
	    content: "",
	  },
	  rolePermissionList:[]
    };
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    getData() {
      Ajax.get("/usermanage/role/selectRoleDetailByRoleId", {
        roleId: this.id,
      }).then((res) => {
        if (res.code === "0000") {
          this.roleData = res.data;
		  this.formDataPrivilege=this.morenlist
		  this.rolePermissionList=res.data.rolePermissionList
          this.formDataPrivilege.forEach((item) => {
            res.data.rolePermissionList.map((item1) => {
              if (
                item.moduleName === item1.moduleName &&
                item1.hasModule === "1"
              ) {
                item.ismodulePermission = true;
                item.privilegeList.map((item2) => {
                  JSON.parse(item1.permissions).map((item3) => {
                    if (item3.lable === item2.name) { 
                      item2.optionsVal = item3.value;
                    }
                  });
                });
              }
            });
          });
        }
      });
    },
    handleEditRole() {
      this.isEdit = !this.isEdit;
    },
    handleCancel() {
      this.isEdit = !this.isEdit;
      this.formDataPrivilege.map((item) => {
        item.ismodulePermission = false;
      });
      this.getData();
    },
    handleSubmit() {
      let rolePermissionList = [],
        obj = {};
      this.formDataPrivilege.forEach((item) => {
        if (item.ismodulePermission) {
          let permission = [],
            obj = {};
          item.privilegeList.forEach((item2) => {
            let option = {};
            option = {
              lable: item2.name,
              value: item2.optionsVal,
            };
            permission.push(option);
          });

          obj = {
            moduleName: item.moduleName,
            hasModule: item.ismodulePermission ? "1" : "0",
            permissions: JSON.stringify(permission),
          };
          rolePermissionList.push(obj);
        }
      });

      obj.rolePermissionList = rolePermissionList;

      let params = Object.assign({}, obj, {
        id: this.id,
      });
	
      Ajax.post("/usermanage/role/update", params).then((res) => {
        if (res.code === "0000") {
          this.isEdit = !this.isEdit;
          this.handleSnack("Role has been edited");
          this.getData();
        } else {
          this.handleSnack(res.message);
        } 
      });
    },
    openFormDialog(type) {
      switch (type) {
        case "duplicate":
          this.formDialogAdd.title = this.formDialogAdd.duplicate;
          this.formDialogAdd.submitBtnTxt = "Duplicate"; 
          this.formDialogAdd.id = this.roleData.id;
          this.formDialogAdd.dataList.forEach((i) => {
            i.value = this.roleData[i.key];
            if (i.key === "name") {
              i.value = `Copy of ${this.roleData[i.key]}`;
            }
          });
          break;
        case "edit":
          this.formDialogAdd.title = this.formDialogAdd.edit;
          this.formDialogAdd.submitBtnTxt = "Save";
          this.formDialogAdd.id = this.roleData.id;

          this.formDialogAdd.dataList.forEach((i) => {
            i.value = this.roleData[i.key];
          });
          break;
      }
      this.formDialogAdd.id = this.roleData.id;
      this.formDialogAdd.visible = true;
    },
    handleAddForm() {
      if (this.formDialogAdd.title === this.formDialogAdd.edit) {
        this.handleEditSubmit();
      } else {
        this.handleAddSubmit();
      }
    },
    handleEditSubmit() {
      let formData = {};
      this.formDialogAdd.dataList.forEach((item) => {
        formData[item.key] = item.value;
      });
      formData.id = this.formDialogAdd.id;
		formData.rolePermissionList=this.rolePermissionList
      Ajax.post("/usermanage/role/update", formData).then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Role info has been edited");
          this.getData();
        } else {
          this.handleSnack(res.message);
        }
      });
    },
    handleAddSubmit() {
      let formData = {};
      this.formDialogAdd.dataList.forEach((item) => {
        formData[item.key] = item.value;
      });

      formData.userId = this.$store.state.user.userId;
      formData.id = this.formDialogAdd.id;

      Ajax.post("/usermanage/role/duplicate", formData).then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Role has been duplicated");
          this.$router.push("/companyRoles");
        } else {
          this.handleSnack(res.message);
        }
      });
    },
    handleDelete() {
      if (!this.roleData.using) {
        this.$confirm(
          "Once the role is deleted, you will not be able to recover it.",
          "Delete role ?",
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
            Ajax.get("/usermanage/role/delete", { id: this.roleData.id }).then(
              (res) => {
                if (res.code === "0000") {
                  this.handleSnack("Role has been deleted");
                  this.$router.push("/companyRoles");
                } else {
                  this.handleSnack(res.message);
                }
              }
            );
          })
          .catch(() => {});
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  beforeRouteLeave(to, form, next) {
    if (this.isEdit) {
      this.formDialogView.visible = true;
    } else {
      next();
    }
  },
};
</script>
<style lang='less'>
//@import url(); 引入公共css类
.icon-edit {
  cursor: pointer;
}
.roles-content {
  /* height: 100%; */
  overflow-y: auto;
  padding-bottom:112px!important;
  // 
}
.text-reminder {
  margin-top: 40px;
  font-size: 12px;
  font-family: Roboto;
  color: #b4b4b4;
}
.roleinfo-card {
  margin-left: 80px;
  border-radius: 13px;
  border: 1px solid #e0e0e0;
  li {
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
    font-family: Roboto;
    font-size: 14px;
    color: #5f6368;
    // word-break: break-all;
    word-wrap: break-word;
    &:last-child {
      border: 0;
    }
    .operate {
      margin-bottom: 8px;
      padding-left: 28px;
      line-height: 24px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .operate.disabled {
      color: #b4b4b4;
      cursor: not-allowed;
    }
  }
  &-lable {
    margin-bottom: 8px;
    display: inline-block;
    padding: 5px 8px;
    background: #eeeeee;
    border-radius: 2px;
  }
  &-name {
    margin-bottom: 8px; 
    font-family: "Open Sans";
    color: #202124;
    line-height: 30px;
  }
  &-dele {
    background: url("~@/assets/images/icons/icon-dele.svg") no-repeat left top;
    background-size: 24px 24px;
  }
  &-edit {
    background: url("~@/assets/images/icons/icon-edit.svg") no-repeat left top;
    background-size: 24px 24px;
  }
  &-duplicate {
    background: url("~@/assets/images/icons/icon-duplicate.svg") no-repeat left
      top;
    background-size: 24px 24px;
  }
}
</style>